import React , {useState , useEffect} from 'react'
import { Table , Input, Spin} from 'antd';
import './accessories.scss'
import {FiSearch} from 'react-icons/fi'
import {RiEye2Line , RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri'
import moment from 'moment'
import {FaBuysellads} from 'react-icons/fa'
import CustomDeleteModal from '../custom_components/customDeleteModal';
import { IconButton, PrimaryButton } from '../custom_components/customButton';
import {MdAddCircle} from 'react-icons/md';
import { colorScheme } from '../contants/variables';
import { fetchAccessories, deleteAccessory } from '../stores/api_calls/accessories';
import { error, success } from '../contants/snackbars';
// import CategoryForm from "../forms/accessories/CategoryForm";
// import AccessoryForm from "../forms/accessories/AccessoryForm";
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import Kjur from '../stores/utils/jwt'

const DraftAccessoriesList = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const [tableData,setTableData] = useState([])
    const [isLoading,setIsLoading] = useState([])
    const [isEdit , setIsEdit] = useState(false)
    const [keywordSearch , setKeywordSearch] = useState("")
    const [showModal , setShowModal] = useState({
        showDelete:false , 
        showForm:false , 
        showPreview:false ,
        showCategory: false,
        data:{}
    })
    const [tablePagination , setTablePagination] = useState({
        current: 1,
        pageSize: 10,
        total:10,
        defaultPageSize: 10, 
        showSizeChanger: true, 
        pageSizeOptions: ['10', '20', '30']
    })
    // const [categories, setCategories] = useState([]);

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            // sorter: true,
            render: (text ,record) => `${record.title}`,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            render: (text ,record) => `$ ${record.price}`,
        },
        {
            title: 'Category',
            dataIndex: 'categories',
            render: (text ,record) => `${record.category_detail.category_name}`,
        },
        {
            title: 'Suitable For',
            dataIndex: 'suitableFor',
            render: (text ,record) => `${record.brand_id != null ? record.brand_detail?.brand_name : '-'}`,
        },
        {
            title: 'Condition',
            dataIndex: 'condition',
            render: (text ,record) => `${record.condition}`,
        },
        {
            title: 'Advertised By',
            dataIndex: 'dealer',
            render: (text ,record) => `${record.advertisement_contact_details?.name}`,
        },
        {
            title: "Date Created",
            dataIndex: "dateCreated",
            // sorter: true,
            render: (text, record) =>
              moment.utc(record.date_created).local().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title:'Actions',
            dataIndex:'actions',
            render:(text,record)=>(
                <div className='actions-icons-container'>
                    <IconButton className='icon-button edit' tootTipTitle="Edit Accessories" Icon={<RiEdit2Fill/>} onClick={()=>onEdit(record)} />
                    <IconButton className='icon-button delete' tootTipTitle="Delete Accessories" Icon={<RiDeleteBin2Fill/>} onClick={()=>onDelete(record)}/>
                </div>
            )
        }
    ];

    useEffect(()=>{
        refetchLists()
    },[])

    useEffect(()=>{
        if(tablePagination.current || tablePagination.pageSize){
            refetchLists()
        }
    },[tablePagination.current , tablePagination.pageSize])

    const refetchLists = () => {
        const pagination = {
            page:tablePagination.current,
            limit:tablePagination.pageSize,
            keyword:keywordSearch,
            is_verified: false,
            is_sold: false,
            is_draft: true,
        }

        fetchData(pagination)
    }

    const fetchData = (data) => {

        setIsLoading(true)

        const accessories = fetchAccessories(data)

        accessories.then((res)=>{
            // console.log(res.data);
            if(res.data.success){
                setTableData(res.data.data)
                setTablePagination({
                    ...tablePagination,
                    total:res.data.meta.pagination.total
                })
                setIsLoading(false)
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            const pagination = {
                page : 1,
                limit : 10,
                keyword:keywordSearch,
                is_verified: false,
                is_sold: false,
                is_draft: true,
            }
            fetchData(pagination)
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTablePagination(pagination)
    };

    const onDelete = (record) => {
        setShowModal({...showModal , showDelete:true , data:record})
    }

    const onDeleteSubmit = () => {
        const removeAccessory = deleteAccessory(showModal.data.id)
        removeAccessory.then((res)=>{
            if(res.data.success){
                success("Accessories Deleted Successfully")
                setShowModal({...showModal , showDelete:false , data:{}})
                refetchLists()
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const onOpenForm = (action , record) => {
        if(action==="add"){
            setIsEdit(false)
            setShowModal({...showModal , showForm:true})
        }else{
            // console.log(record);
            setIsEdit(true)
            setShowModal({...showModal , showForm:true , data:record})
        }
    }

    const onAdd  = () => {
        navigate("../car-accessories/create", { replace: true })
    }

    const onEdit = (record) => {
        delete record.description;
        const token = Kjur.encode(record);
        // console.log(record);
        navigate(
            {
                pathname:'../car-accessories/edit',
                search:`?${createSearchParams({token:token})}`,
            },
            {replace:true}
        )
    }

    const onSubmitForm = () => {
        setShowModal({...showModal , showForm:false , data:{}})
    }

    return (
        <div className='banner-table-container'>
            <div className='table-title-icon primary'>
                <FaBuysellads/>
            </div>
            <p className='table-title-text primary'>Draft Accessories</p>

            <div
                className='search-container'
            >
                {/* <div className="btn-div">
                    <PrimaryButton
                    label="Manage Categories"
                    color={colorScheme.primary}
                    onClick={() => setShowModal({ ...showModal, showCategory: true })}
                    />
                </div>
                <div className='add-button-container'>
                    <PrimaryButton 
                        Icon={<MdAddCircle/>} 
                        color={colorScheme.primary} 
                        label="Create Accessories" 
                        // onClick={()=>onOpenForm('add')}
                        onClick={()=>onAdd()}
                    />
                </div> */}

                <Input
                    style={{width:300}}
                    size='large'
                    placeholder='Search Record'
                    prefix={<FiSearch/>}
                    onChange={e=>setKeywordSearch(e.target.value)}
                    onPressEnter={onEnter}
                />
            </div>
            
            <div className='table-div'>
                <Table
                    columns={columns}
                    rowKey={record => record._id}
                    dataSource={tableData}
                    pagination={tablePagination}
                    loading={{indicator:<Spin size='large'/> , spinning:isLoading}}
                    onChange={handleTableChange}
                    bordered
                    sticky
                />
            </div>

            {/* <CategoryForm
                visible={showModal.showCategory}
                onCancel={() =>
                setShowModal({ ...showModal, showCategory: false, data: {} })
                }
                sections={categories}
                setSections={setCategories}
            /> */}
            
            <CustomDeleteModal
                title="Delete Accessories"
                visible={showModal.showDelete}
                onSubmit={onDeleteSubmit}
                onCancel={()=>setShowModal({...showModal , showDelete:false , data:{}})}
                data={{itemName:`${showModal.data.title}` , type:"accessory"}}
            />

        </div>
    )
}

export default DraftAccessoriesList;
