import {
    Input,
    Checkbox,
    Modal,
    Select,
    Space,
    DatePicker,
    Radio,
    Divider,
    Row,
    Col,
  } from "antd";
  import React, { useRef, useState, useEffect } from "react";
  import "./banner.scss";
  import { FaUpload } from "react-icons/fa";
  import { uploadImage, uploadVideo } from "../../stores/api_calls/upload";
  import { error, success } from "../../contants/snackbars";
  import { addBanner, editBanner } from "../../stores/api_calls/bannerlists";
  import moment from "moment";
  import { validateBannerForm } from "../../stores/helpers/bannerFormValidation";
  import { IconButton } from "../../custom_components/customButton";
  import {
    bannerTypes,
    pageTypes,
    colorScheme,
    adsLocations,
  } from "../../contants/variables";
  
  const AdForm = (props) => {
    const { isEdit, data, onCancel, visible, fetchData, tablePagination } = props;
    const [submitLoading, setSubmitLoading] = useState(false);
    const fileUploader = useRef(null);
    const fileUploaderTablet = useRef(null);
    const fileUploaderMobile = useRef(null);
    const fileUploaderVideo = useRef(null);
    const [selectedFile, setSelectedFile] = useState({ file: null, url: "" });
    const [selectedFileTablet, setSelectedFileTablet] = useState({ file: null, url: "" });
    const [selectedFileMobile, setSelectedFileMobile] = useState({ file: null, url: "" });
    const [selectedVideo, setSelectedVideo] = useState({ file: null, url: "" });
    const [form, setForm] = useState({
      bannerId: "",
      bannerName: "",
      adType: "photo",
      bannerType: "",
      pageType: "",
      url: "https://sgcarlist.com",
      is_external_link: false,
      clientName: "",
      startDate: moment.utc().local().format("YYYY-MM-DD"),
      endDate: moment.utc().local().format("YYYY-MM-DD"),
      imageUrl: "",
      tabletImageUrl: "",
      mobileImageUrl: "",
      imageId: "",
      // tabletImageId: "",
      // mobileImageId: "",
      adsLocation: "",
      timer: null,
      bannerVideo: "",
      videoType: "youtube",
      localVideoUrl: "",
      localVideoId: "",
    });

    useEffect(() => {
      if (isEdit) {
        console.log(data);
        setForm({
          ...form,
          bannerId: data.id,
          bannerName: data.name,
          adType: data.ad_type,
          bannerType: data.banner_type,
          pageType: data.page_type,
          url: data.link,
          is_external_link: data.is_external_link,
          clientName: data.client_name,
          startDate: moment.utc(data.start_date).local().format("YYYY-MM-DD"),
          endDate: moment.utc(data.end_date).local().format("YYYY-MM-DD"),
          imageId: data.banner_image_urls ? data.banner_image_urls._id["$oid"] : null,
          imageUrl: data.banner_image_urls ? data.banner_image_urls.metadata.image_url : null,
          bannerVideo: data.banner_video,
          tabletImageUrl: data.tablet_banner_image_url,
          mobileImageUrl: data.mobile_banner_image_url,

          videoType: data.video_type,
          localVideoUrl: data.local_video_url,
          localVideoId: data.banner_video_urls ? data.banner_video_urls._id["$oid"] : null,
        });
      } else {
        setForm({
          ...form,
          imageUrl: selectedFile.url,
          tabletImageUrl: selectedFileTablet.url,
          mobileImageUrl: selectedFileMobile.url,
          localVideoUrl: selectedVideo.url,
        });
      }
    }, [isEdit]);
  
    const refetchLists = () => {
      const pagination = {
        page: tablePagination.current,
        limit: tablePagination.pageSize,
        keyword: "",
      };
      fetchData(pagination);
    };

    const onCheckboxChange = (name, e) => {
      setForm({
        ...form,
        [name]: e.target.checked,
      });
    };

    const handleFieldChange = (name, e) => {
      setForm({ ...form, [name]: e.target.value });
    };
  
    const handleDropdownChange = (name, value) => {
      setForm({ ...form, [name]: value });
    };
  
    const onDateFieldChange = (name, value) => {
      setForm({ ...form, [name]: value });
    };

    const handleOptionChange = (name, e) => {
      setForm({ ...form, [name]: e.target.value })
    };
  
    const handleOpenGallery = () => {
      fileUploader.current.click();
    };

    const handleOpenGalleryTablet = () => {
      fileUploaderTablet.current.click();
    };

    const handleOpenGalleryMobile = () => {
      fileUploaderMobile.current.click();
    };
    
    const handleOpenGalleryVideo = () => {
      fileUploaderVideo.current.click();
    };
  
    const handleImageChange = (e) => {
      setSelectedFile({ ...selectedFile, file: null, url: "" });
      if (e.target.files) {
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        setSelectedFile({ ...selectedFile, file: file, url: url });
        setForm({ ...form, imageUrl: url });
      }
    };

    const handleTabletImageChange = (e) => {
      setSelectedFileTablet({ ...selectedFileTablet, file: null, url: "" });
      if (e.target.files) {
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        setSelectedFileTablet({ ...selectedFileTablet, file: file, url: url });
        setForm({ ...form, tabletImageUrl: url });
      }
    };
    
    const handleMobileImageChange = (e) => {
      setSelectedFileMobile({ ...selectedFileMobile, file: null, url: "" });
      if (e.target.files) {
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        setSelectedFileMobile({ ...selectedFileMobile, file: file, url: url });
        setForm({ ...form, mobileImageUrl: url });
      }
    };
    
    const handleVideoChange = (e) => {
      setSelectedVideo({ ...selectedVideo, file: null, url: "" });
      if (e.target.files) {
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        setSelectedVideo({ ...selectedVideo, file: file, url: url });
        setForm({ ...form, localVideoUrl: url });
      }
    };
  
    const onClose = () => {
      setSelectedFile({ ...selectedFile, file: null, url: "" });
      setForm({
        ...form,
        bannerName: "",
        adType: "photo",
        bannerType: "",
        pageType: "",
        url: "https://",
        clientName: "",
        startDate: moment(),
        endDate: moment(),
        imageUrl: "",
        bannerVideo: "",
      });
      onCancel();
    };
  
    const onSubmit = async () => {
      setSubmitLoading(true);
      var data = form;

      const handleUpload = async (file, key, imgId) => {
        if (file.file) {
          try {
            const res = await uploadImage(file.file);
            if (res.data.success) {
              data[key] = res?.data?.data?.metadata?.image_url || null;
              data[imgId] = res?.data?.data?._id || null;
            }
          } catch (e) {
            error(e.response.data.server_response);
          }
        }
      };

      const handleUploadVideo = async (file, key, videoId) => {
        if (file.file) {
          try {
            const res = await uploadVideo(file.file);
            console.log(res.data);
            if (res.data.success) {
              data[key] = res?.data?.data?.metadata?.file_url || null;
              data[videoId] = res?.data?.data?._id || null;
            }
          } catch (e) {
            error(e.response.data.server_response);
          }
        }
      };

      await handleUpload(selectedFile, "imageUrl", "imageId");
      await handleUpload(selectedFileTablet, "tabletImageUrl", "tabletImageId");
      await handleUpload(selectedFileMobile, "mobileImageUrl", "mobileImageId");
      await handleUploadVideo(selectedVideo, "localVideoUrl", "localVideoId");

      onCreateBanner(data);
      // if(selectedFile.file) {
      //   const upload = uploadImage(selectedFile.file);
        
      //   upload
      //     .then((res) => {
      //       if (res.data.success) {
      //         data.imageId = res.data.data._id;
    
      //         onCreateBanner(data);
      //       }
      //     })
      //     .catch((e) => {
      //       error(e.response.data.server_response);
      //     });
      // } else {
      //   onCreateBanner(data);
      // }
    };

    const onCreateBanner = (data) => {
      const createBanner = addBanner(data);

      createBanner
        .then((res) => {
          if (res?.data?.success) {
            refetchLists();
            success("New Banner Added Successfully");
            onClose();
            setSubmitLoading(false);
          }
        })
        .catch((e) => {
          error(
            `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
          );
        });
    }
  
    const onUpdate = async () => {
      setSubmitLoading(true);
      var data = form;

      const handleUpload = async (file, key, imgId) => {
        if (file.file) {
          try {
            const res = await uploadImage(file.file);
            if (res.data.success) {
              data[key] = res?.data?.data?.metadata?.image_url || null;
              data[imgId] = res?.data?.data?._id || null;
            }
          } catch (e) {
            error(e.response.data.server_response);
          }
        }
      };

      const handleUploadVideo = async (file, key, videoId) => {
        if (file.file) {
          try {
            const res = await uploadVideo(file.file);
            console.log(res.data);
            if (res.data.success) {
              data[key] = res?.data?.data?.metadata?.file_url || null;
              data[videoId] = res?.data?.data?._id || null;
            }
          } catch (e) {
            error(e.response.data.server_response);
          }
        }
      };

      await handleUpload(selectedFile, "imageUrl", "imageId");
      await handleUpload(selectedFileTablet, "tabletImageUrl", "tabletImageId");
      await handleUpload(selectedFileMobile, "mobileImageUrl", "mobileImageId");
      await handleUploadVideo(selectedVideo, "localVideoUrl", "localVideoId");

      onUpdateBanner(data);
      // if (selectedFile.file) {
      //   const upload = uploadImage(selectedFile.file);
      //   upload
      //     .then((res) => {
      //       if (res.data.success) {
      //         data.imageId = res.data.data._id;
      //         onUpdateBanner(data);
      //       }
      //     })
      //     .catch((e) => {
      //       error(e.response.data.server_response);
      //     });
      // } else {
      //   onUpdateBanner(data);
      // }
    };
  
    const onUpdateBanner = (data) => {
      setSubmitLoading(true);
      const updateBanner = editBanner(data);
  
      updateBanner
        .then((res) => {
          if (res.data.success) {
            refetchLists();
            success("Banner Updated Successfully");
            onClose();
            setSubmitLoading(false);
          }
        })
        .catch((e) => {
          error(
            `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
          );
        });
    };
  
    const generateSizing = (type, multiplier) => {
      if (type === "Hero-Banner") {
        return `${350 * multiplier} height x ${1520 * multiplier} width pixels`;
      } else if (type === "Strip-Banner") {
        return `${100 * multiplier} height x ${1200 * multiplier} width pixels`;
      } else if (type === "Popout-Long") {
        return `${100 * multiplier} height x ${1520 * multiplier} width pixels`;
      } else if (type === "Popout-Rectangle") {
        return `${400 * multiplier} height x ${600 * multiplier} width pixels`;
      } else if (type === "Vertical-Banner" || type === "Auth-Left-Side" || type === "Auth-Right-Side") {
        return `${540 * multiplier} height x ${300 * multiplier} width pixels`;
      } else if (type === "Product-View-Center-Ad") {
        return `${300 * multiplier} length x ${862 * multiplier} width pixels`;
      } else if (type === 'Plate-Numbers-Fade-Slide-Ad' || type === "Car-Rental-Fade-Slide-Ad" || type === "Commercial-Vehicle-Fade-Slide-Ad") {
        return `${280 * multiplier} height x ${393 * multiplier} width pixels`;
      } else if (type === "Desktop-Product-Banner" || type === "rental-strip-banner-ad") {
        return `${200 * multiplier} height x ${960 * multiplier} width pixels`;
      } else if (type === "Plate-Numbers-Strip-Banner" || type === "Home-Strip-Banner-1" || type === "Home-Strip-Banner-2" ||
       type === "Home-Strip-Banner-3" || type === "Home-Strip-Banner-4" || type === "Home-Strip-Banner-5") {
        return `${200 * multiplier} height x ${980 * multiplier} width pixels`;
      } else if (type === "Vertical-Side-Banner") {
        return `${600 * multiplier} height x ${300 * multiplier} width pixels`;
      } else if (type === 'Strip-Banner-I-Max' || type === "valuation-Rectangle-strip-banner-ad") {
        return `${360 * multiplier} height x ${700 * multiplier} width pixels`;
      } else if (type === 'I-Max') {
        return `${250 * multiplier} height x ${700 * multiplier} width pixels`;
      } else if (type === 'Mobile-Ad') {
        return `${100 * multiplier} height x ${400 * multiplier} width pixels`;
      } else {
        return `${300 * multiplier} height x ${300 * multiplier} width pixels`;
      }
    };

    const generateTabletSizing = (type, multiplier) => {
      if (type === "Hero-Banner") {
        return `${500 * multiplier} height x ${1024 * multiplier} width pixels`;
      }
    }
    
    const generateMobileSizing = (type, multiplier) => {
      if (type === "Hero-Banner") {
        return `${200 * multiplier} height x ${400 * multiplier} width pixels`;
      }
    }
  
    return (
      <div>
        <Modal
          className="formModal"
          title={`${isEdit ? "Edit" : "Create"} Ad Banner`}
          visible={visible}
          width={800}
          onOk={isEdit ? onUpdate : onSubmit}
          okButtonProps={{ disabled: !validateBannerForm(form) }}
          onCancel={onClose}
          okText={`${isEdit ? "Save" : "Submit"}`}
          confirmLoading={submitLoading}
        >
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <div className="form-container">
                <p className="form-label">
                  Banner Name <span>*</span> :{" "}
                </p>
                <Input
                  value={form.bannerName}
                  onChange={(text) => handleFieldChange("bannerName", text)}
                  placeholder="Please type Banner Name"
                />
              </div>
  
              <div className="form-container">
                <p className="form-label">
                  Link Url <span>*</span> :{" "}
                </p>
                <Input
                  value={form.url}
                  onChange={(text) => handleFieldChange("url", text)}
                  placeholder="Please type link url"
                />
              </div>
              <div className="form-container">
                <p className="form-label"></p>
                <div style={{width: '100%'}}>
                  <Checkbox
                    valuePropName="checked"
                    checked={form.is_external_link}
                    onChange={(e) => onCheckboxChange("is_external_link", e)}
                  >
                    Is external link
                  </Checkbox>
                </div>
              </div>

  
              <div className="form-container">
                <p className="form-label">
                  Client Name <span>*</span> :{" "}
                </p>
                <Input
                  value={form.clientName}
                  onChange={(text) => handleFieldChange("clientName", text)}
                  placeholder="Please type Client Name"
                />
              </div>
  
              <div className="form-container">
                <p className="form-label">
                  Start Date <span>*</span> :{" "}
                </p>
                <Space>
                  <div className="datepicker">
                    <DatePicker
                      style={{ width: "100%" }}
                      value={moment(form.startDate)}
                      onChange={(date, dateString) =>
                        onDateFieldChange("startDate", dateString)
                      }
                    />
                  </div>
                </Space>
              </div>
  
              {visible ? (
                <div className="form-container">
                  <p className="form-label">
                    End Date <span>*</span> :{" "}
                  </p>
                  <Space>
                    <div className="datepicker">
                      <DatePicker
                        style={{ width: "100%" }}
                        value={moment(form.endDate)}
                        onChange={(date, dateString) =>
                          onDateFieldChange("endDate", dateString)
                        }
                      />
                    </div>
                  </Space>
                </div>
              ) : null}
  
              {/* <div className="form-container">
                <p className="form-label">
                  Ads Location <span>*</span> :{" "}
                </p>
                <Select
                  value={form.adsLocation}
                  onChange={(value) => handleDropdownChange("adsLocation", value)}
                  style={{ width: "100%" }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {adsLocations.map((el, i) => (
                    <Select.Option key={i} value={el.value}>
                      {`${el.label}`}
                    </Select.Option>
                  ))}
                </Select>
              </div>
  
              <div className="form-container">
                <p className="form-label">
                  Timer <span>*</span> :{" "}
                </p>
                <Input
                  value={form.timer}
                  onChange={(text) => handleFieldChange("timer", text)}
                  placeholder="Timer"
                />
              </div> */}

              <div className="form-container">
                    <p className="form-label">
                      Ad Type <span>*</span> :{" "}
                    </p>
                    <div style={{width: '100%'}}>
                      <Radio.Group 
                        onChange={(value)=> handleOptionChange("adType", value)} 
                        value={form.adType || undefined}>
                        <Radio value="photo">Photo</Radio>
                        <Radio value="video">Video</Radio>
                      </Radio.Group>
                    </div>
              </div>
            </Col>
            <Col span={24}>
              {form.adType === "photo" ? (
                <div>
                  {/* <p style={{ margin: "5px 0x", color: colorScheme.red }}>
                    ** Kindly select a banner type to see image requirements **
                  </p> */}
                  <p style={{ margin: "5px 0x", color: colorScheme.red }}>
                    {`To avoid stretching of image in the website. The image should not exceed 2MB(megabyte) and has to be (.jpg , .png) with a minimum size and same ratio.`}
                  </p>
                </div>
              ) : (
                <></>
              )}
  
              <div className="form-container">
                <p className="form-label">
                  Page Type <span>*</span> :{" "}
                </p>
                <Select
                  showSearch
                  value={form.pageType || undefined}
                  onChange={(value) => handleDropdownChange("pageType", value)}
                  placeholder="Search to Select"
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {pageTypes.map((page, i) => (
                    <Select.Option key={i} value={page.value}>
                      {page.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              
              <div className="form-container">
                <p className="form-label">
                  Banner Type <span>*</span> :{" "}
                </p>
                <Select
                  showSearch
                  value={form.bannerType || undefined}
                  onChange={(value) => handleDropdownChange("bannerType", value)}
                  placeholder="Search to Select"
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {bannerTypes.map((banner, i) => (
                    <Select.Option key={i} value={banner.value}>
                      {banner.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
  
              {form.adType == "photo" ?
                <>
                  {/* <p
                    style={{
                      margin: "5px 0x",
                      color: colorScheme.green,
                      fontSize: "0.9em",
                      display: "flex",
                    }}
                  >
                    <span style={{ marginRight: "10px" }}>
                      Example & Valid Sizing :
                    </span>
                    <span>
                      {generateSizing(form.bannerType, 1)} <br />
                      {generateSizing(form.bannerType, 1.2)} <br />
                      {generateSizing(form.bannerType, 1.5)} <br />
                      {generateSizing(form.bannerType, 2)} <br />
                    </span>
                  </p> */}
                  <div className="form-container label-container">
                    <p className="form-label"></p>
                    <p style={{width: '100%'}}><span style={{color: colorScheme.green, fontSize: '0.9em'}}>{generateSizing(form.bannerType, 1)}</span></p>
                  </div>
                  <div className="form-container photo-container">
                    <p className="form-label">
                      Banner Image <span>*</span> :{" "}
                    </p>
                    <div className="upload-container" style={{width: '100%'}} onClick={handleOpenGallery}>
                      <FaUpload />
                      <p className="banner-p">UPLOAD BANNER PHOTO</p>
                    </div>
      
                    <input
                      ref={fileUploader}
                      id="getFile"
                      type="file"
                      hidden
                      onChange={handleImageChange}
                    />
                  </div>
                  {
                    form.imageUrl &&
                    <div className="image-preview-container">
                      <img src={form.imageUrl} />
                    </div>
                  }
                  {form.bannerType == 'Hero-Banner' && (
                    <>
                      {/* <div>
                        <p
                          style={{
                            margin: "5px 0x",
                            color: colorScheme.green,
                            fontSize: "0.9em",
                            display: "flex",
                          }}
                        >
                          <span style={{ marginRight: "10px" }}>
                            Example & Valid Sizing :
                          </span>
                          <span>
                            {generateTabletSizing(form.bannerType, 1)} <br />
                            {generateTabletSizing(form.bannerType, 1.2)} <br />
                            {generateTabletSizing(form.bannerType, 1.5)} <br />
                            {generateTabletSizing(form.bannerType, 2)} <br />
                          </span>
                        </p>
                      </div> */}
                      <div className="form-container label-container">
                        <p className="form-label"></p>
                        <p style={{width: '100%'}}><span style={{color: colorScheme.green, fontSize: '0.9em'}}>{generateTabletSizing(form.bannerType, 1)}</span></p>
                      </div>
                      <div className="form-container photo-container">
                        <p className="form-label">
                          Tablet Banner Image :{" "}
                        </p>
                        <div className="upload-container" style={{width: '100%'}} onClick={handleOpenGalleryTablet}>
                          <FaUpload />
                          <p className="banner-p">UPLOAD TABLET BANNER PHOTO</p>
                        </div>
          
                        <input
                          ref={fileUploaderTablet}
                          id="getFileTablet"
                          type="file"
                          hidden
                          onChange={handleTabletImageChange}
                        />
                      </div>
                      {
                        form.tabletImageUrl &&
                        <div className="image-preview-container">
                          <img src={form.tabletImageUrl} />
                        </div>
                      }


                      {/* <div>
                        <p
                          style={{
                            margin: "5px 0x",
                            color: colorScheme.green,
                            fontSize: "0.9em",
                            display: "flex",
                          }}
                        >
                          <span style={{ marginRight: "10px" }}>
                            Example & Valid Sizing :
                          </span>
                          <span>
                            {generateMobileSizing(form.bannerType, 1)} <br />
                            {generateMobileSizing(form.bannerType, 1.2)} <br />
                            {generateMobileSizing(form.bannerType, 1.5)} <br />
                            {generateMobileSizing(form.bannerType, 2)} <br />
                          </span>
                        </p>
                      </div> */}
                      <div className="form-container label-container">
                        <p className="form-label"></p>
                        <p style={{width: '100%'}}><span style={{color: colorScheme.green, fontSize: '0.9em'}}>{generateMobileSizing(form.bannerType, 1)}</span></p>
                      </div>
                      <div className="form-container photo-container">
                        <p className="form-label">
                          Mobile Banner Image :{" "}
                        </p>
                        <div className="upload-container" style={{width: '100%'}} onClick={handleOpenGalleryMobile}>
                          <FaUpload />
                          <p className="banner-p">UPLOAD MOBILE BANNER PHOTO</p>
                        </div>
                        <input
                          ref={fileUploaderMobile}
                          id="getFileMobile"
                          type="file"
                          hidden
                          onChange={handleMobileImageChange}
                        />
                      </div>
                      {
                        form.mobileImageUrl &&
                        <div className="image-preview-container">
                          <img src={form.mobileImageUrl} />
                        </div>
                      }
                    </>
                  )}
                </>
                :
                <>
                  <div className="form-container">
                      <p className="form-label">
                        Video Type :{" "}
                      </p>
                      <div style={{width: '100%'}}>
                        <Radio.Group 
                          onChange={(value)=> handleOptionChange("videoType", value)} 
                          value={form.videoType || undefined}>
                          <Radio value="youtube">Youtube</Radio>
                          <Radio value="local">Local</Radio>
                        </Radio.Group>
                      </div>
                  </div>

                  {form.videoType == "local" && (
                    <div className="form-container" style={{justifyContent:"end"}}>
                      <span style={{color:"red"}}>Only MP4 video allowed.</span>
                    </div>
                  )}

                  <div className="form-container video-container">
                        <p className="form-label">
                          Banner Video <span>*</span> :{" "}
                        </p>
                        {form.videoType == 'youtube' ? (
                          <Input
                            value={form.bannerVideo}
                            onChange={(text) => handleFieldChange("bannerVideo", text)}
                            placeholder="Please input Banner Video"
                          />
                        ):(
                          <>
                            <div className="upload-container" style={{width: '100%'}} onClick={handleOpenGalleryVideo}>
                              <FaUpload />
                              <p className="banner-p">UPLOAD Video</p>
                            </div>
                            
                            <input
                              ref={fileUploaderVideo}
                              id="getFileVideo"
                              type="file"
                              hidden
                              onChange={handleVideoChange}
                              accept=".mp4"
                            />
                          </>
                        )}
                  </div>

                  {
                    form.localVideoUrl &&
                    <div className="form-container video-container">
                      <div className="image-preview-container" style={{textAlign:'right'}}>
                        <video height="120" width="160" controls >
                          <source src="https://ik.imagekit.io/ikmedia/example_video.mp4" />
                          Your browser does not support the video tag...
                        </video>
                      </div>
                    </div>
                  }
                </>
                
              }

            </Col>
          </Row>
        </Modal>
      </div>
    );
  };
  
  export default AdForm;
  