import {
  Input,
  Row,
  Col,
  Radio,
  Divider,
  DatePicker,
  Space,
  Checkbox,
  Select,
  Tooltip,
} from "antd";
import React, { useState, useEffect, useRef } from "react";
import { FaDownload, FaUpload, FaUserAlt } from "react-icons/fa";
import { IoMdCloseCircle} from 'react-icons/io';
import { IoInformationCircleSharp } from "react-icons/io5";
import { GoDeviceMobile, GoPackage } from "react-icons/go";
import { RiImageAddFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import "./carform.scss";
import moment from "moment";
import { colorScheme, category } from "../../contants/variables";
import {
  LoadingButton,
  PrimaryButton,
  IconButton
} from "../../custom_components/customButton";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { uploadImage } from "../../stores/api_calls/upload";
import { getCommaSeparatedTwoDecimalsNumber } from "../../stores/utils/formatNumber";

const launchYears = [];
const lastYear = moment().add(1, 'y').format('YYYY');
for(var i=2015; i <= lastYear; i++ ) {
  launchYears.push(i.toString());
}
// console.log(launchYears);

const FieldSuffix = (props) => {
  const { TooltipData } = props;
  return (
    <Tooltip
      title={() => {
        return <TooltipData />;
      }}
      placement="bottomRight"
    >
      <IoInformationCircleSharp
        color={colorScheme.primary}
        style={{ fontSize: "20px" }}
      />
    </Tooltip>
  );
};


const ImageUploadComponent = (props) => {
  const { onChange } = props;
  const fileUploader = useRef(null);

  const uploadImageCallBack = (link) => {
    onChange(link, "auto", "100%", "uploaded image");
  };

  const onSelectFile = (e) => {
    const file = e.target.files[0];
    // const localUrl = URL.createObjectURL(file)

    const upload = uploadImage(file);

    upload
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          const url = res.data.data.metadata.image_url;
          uploadImageCallBack(url);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Upload Failed. Please try again`
        );
      });
  };

  const handleOpenGallery = (event) => {
    fileUploader.current.click();
  };

  return (
    <div>
      <div className="icon-button-div">
        <IconButton
          className="icon-button upload"
          tootTipTitle="Upload Image"
          Icon={<RiImageAddFill />}
          onClick={handleOpenGallery}
        />
      </div>

      <input
        ref={fileUploader}
        hidden
        type="file"
        name="image-event"
        id="image-event"
        accept="image/*"
        onChange={onSelectFile}
      />
    </div>
  );
};

const NewCarDetails = (props) => {
  const [downloading, setDownloading] = useState(false);
  const fileUploaderLong1 = useRef(null);
  const fileUploaderLong2 = useRef(null);
  const [salesAgents, setSalesAgents] = useState([]);

  const [specsEditorState, setSpecsEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [featEditorState, setFeatEditorState] = useState(() =>
    EditorState.createEmpty()
  );


  const [descEditorState, setDescEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const { form, setForm, brands, models, dealers, packages, isEdit, selectedFile1, setSelectedFile1, selectedFile2, setSelectedFile2, newCarConditions, saleAdvisers } = props;

  useEffect(() => {
    setSalesAgents(form.productAgents);
  }, [form, isEdit])

  const handleUpdateAgent = (e) => {
    const { name, checked } = e.target;
    
    const contactNumber = name.contactnumber ? name.contactnumber : name.contactNumber;
    if(checked) {
      salesAgents.push({fullName: name.fullName, contactNumber : contactNumber, sorting: name.sorting});
    }else {
      salesAgents = salesAgents.filter((item)=> item.fullName != name.fullName);
    }
    console.log(salesAgents);
    setForm({ ...form, productAgents : salesAgents })
  };

  const handleFieldChange = (name, e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const handleNumberFieldChange = (name, value, maxLength = 7) => {

    if(value.length <= maxLength || value == 0) {
      setForm({
        ...form,
        [name]: value,
      });
    }
    
  };

  const handleSelectPackage = (value) => {
    setForm({ ...form, packageId: value });
  };

  const handleDropdownChange = (name, value) => {
    console.log(name, value);
    setForm({
      ...form,
      [name]: value,
    });
  };

  function downloadFiles() {
    form?.brochures?.forEach((url, index) => {
      const filename = `file${index + 1}.${getFileType(url)}`; // Generate filename for each file (e.g., file1.pdf, file2.pdf, ...)
      downloadSingleFile(url, filename); // Call downloadFile function for each file URL
    });
  }

  function getFileType(text) {
    const parts = text.split(".");
    return parts[parts.length - 1];
  }

  function downloadSingleFile(url, filename) {
     window.open(url, "_blank");
  }

   const handleSpecsEditorChange = (state) => {
     setSpecsEditorState(state);
      setForm({
        ...form,
        ['specsFormatted']: state,
      });
   };
  
  const handleFeatEditorChange = (state) => {
    setFeatEditorState(state);
    setForm({
      ...form,
      ["featFormatted"]: state,
    });
  };

   const handleDescEditorChange = (state) => {
     setDescEditorState(state);
     setForm({
       ...form,
       ["descFormatted"]: state,
     });
   };

  const handleOpenGalleryLong1 = (event) => {
    fileUploaderLong1.current.click();
  };

  const handleDeleteSelectedFile1 = () => {
    setForm({...form , selectedLongAdFile1:null, longAdImage1:""})
  }

  const handleImageChange1 = (e) => {
    setSelectedFile1({ ...selectedFile1, file: null, url: "" });

    if (e.target.files) {
      const file = e.target.files[0]
      const localUrl1 = URL.createObjectURL(file)
      setSelectedFile1({ ...selectedFile1, file: file, url: localUrl1 });
      setForm({...form , longAdImage1:localUrl1})
    }
    
  }
  
  const handleOpenGalleryLong2 = (event) => {
    fileUploaderLong2.current.click();
  };

  const handleDeleteSelectedFile2 = () => {
    setForm({...form , selectedLongAdFile2:null, longAdImage2:""})
  }

  const handleImageChange2 = (e) => {
    setSelectedFile2({ ...selectedFile2, file: null, url: "" });

    if (e.target.files) {
      const file = e.target.files[0]
      const localUrl2 = URL.createObjectURL(file)
      setSelectedFile2({ ...selectedFile2, file: file, url: localUrl2 });
      setForm({...form , longAdImage2:localUrl2})
    }
    
  }

  return (
    <div>
      <div className="header-container">
        <p className="header">Dealer Personal Details</p>
        <p className="sub-header">
          note: (*) fields are required to be filled up
        </p>
      </div>

      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Name <span>*</span> :{" "}
            </p>
            <Select
              showSearch
              value={form.fullname || undefined}
              loading={dealers.loading}
              onChange={(value) => handleDropdownChange("dealerId", value)}
              style={{ width: "100%" }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {[
                {
                  user_first_name: "NOT ON THE LIST DEALER",
                  user_last_name: "",
                  _id: "0",
                },
                {
                  user_first_name: "NOT ON THE LIST DIRECT SELLER",
                  user_last_name: "",
                  _id: "1",
                },
                ...dealers.data,
              ].map((el, i) => (
                <Select.Option key={i} value={el._id}>
                  {`${el.user_first_name} ${el.user_last_name}`}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Mobile Number <span>*</span> :{" "}
            </p>
            <Input
              value={form.dealerMobileNumber}
              prefix={<GoDeviceMobile />}
              onChange={(text) => handleFieldChange("dealerMobileNumber", text)}
              disabled={
                form.dealerId === "0" || form.dealerId === "1" ? false : true
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Email:</p>
            <Input
              value={form.dealerEmail}
              prefix={<MdEmail />}
              onChange={(text) => handleFieldChange("dealerEmail", text)}
              disabled={
                form.dealerId === "0" || form.dealerId === "1" ? false : true
              }
            />
          </div>
        </Col>
      </Row>

      {(form.dealerId === "0" || form.dealerId === "1") && (
        <Row>
          <Col span={8}>
            <div className="form-fields">
              <p className="form-field-label">
                First Name <span>*</span> :{" "}
              </p>
              <Input
                value={form.dealerFirstName}
                onChange={(text) => handleFieldChange("dealerFirstName", text)}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className="form-fields">
              <p className="form-field-label">Last Name: </p>
              <Input
                value={form.dealerLastName}
                onChange={(text) => handleFieldChange("dealerLastName", text)}
              />
            </div>
          </Col>
        </Row>
      )}

      <Divider />

      {form.role === "Advertiser" ? (
        <div>
          <div className="header-container">
            <p className="header">Package Options</p>
            <p className="sub-header">
              note: (*) for advertisers they must select a plan
            </p>
          </div>
          <Row>
            {packages.map((item, index) => (
              <Col span={8} key={index}>
                <div
                  className={`package-card-container ${
                    form.packageId === item._id ? "active" : ""
                  }`}
                  onClick={() => handleSelectPackage(item._id)}
                >
                  <div className="package-title">
                    <p>{item.package_name}</p>
                    <p>S$ {item.package_cost}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Divider />
        </div>
      ) : null}

      <div className="header-container">
        <p className="header">Car Details</p>
        <p className="sub-header">
          note: (*) fields are required to be filled up
        </p>
      </div>

      <Divider />

      <div className="header-container">
        <p className="header">Car Informations</p>
        <p className="sub-header">
          note: (*) fields are required to be filled up
        </p>
      </div>

      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Make & Model <span>*</span> :{" "}
            </p>
            <Input
              value={form.carName}
              onChange={(text) => handleFieldChange("carName", text)}
              suffix={
                <FieldSuffix
                  TooltipData={() => (
                    <p style={{ fontSize: "9px" }}>
                      Name of the car to be displayed in website
                      <br />
                      eg: For SALE Toyota 40% Discount.
                    </p>
                  )}
                />
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Selling Price <span>*</span> :{" "}
            </p>
            <Input
              prefix={
                <div className="fieldPrefix">
                  <p>S$</p>
                </div>
              }
              value={getCommaSeparatedTwoDecimalsNumber(form.askingPrice)}
              onChange={(e) => {
                handleNumberFieldChange(
                  "askingPrice",
                  e.target.value == "" ? 0 : e.target.value.split(",").join(""),
                  7
                )
              }}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Monthly Instalment :{" "}
            </p>
            <Input
              prefix={
                <div className="fieldPrefix">
                  <p>S$</p>
                </div>
              }
              value={getCommaSeparatedTwoDecimalsNumber(form.monthly_installment_price)}
              onChange={(e) => {
                handleNumberFieldChange(
                  "monthly_installment_price",
                  e.target.value == "" ? 0 : e.target.value.split(",").join(""),
                  5
                )
              }}
              suffix={
                <FieldSuffix
                  TooltipData={() => (
                    <p style={{ fontSize: "9px" }}>You may enter instalment plan.</p>
                  )}
                />
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Car Brand <span>*</span> :{" "}
            </p>
            <Select
              showSearch
              value={form.carBrand || undefined}
              loading={brands.loading}
              onChange={(value) => handleDropdownChange("carBrand", value)}
              style={{ width: "100%" }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {brands.data.map((el, i) => (
                <Select.Option key={i} value={el._id}>
                  {el.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Car Model <span>*</span> :{" "}
            </p>
            <Select
              showSearch
              value={form.carModel || undefined}
              disabled={!models.data.length}
              loading={models.loading && form.carBrand}
              onChange={(value) => handleDropdownChange("carModel", value)}
              style={{ width: "100%" }}
              placeholder={
                models.loading
                  ? `Fetching list of models`
                  : !models.data.length
                  ? `No model available in this brand`
                  : `Search to Select`
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {models.data.map((el, i) => (
                <Select.Option key={i} value={el._id}>
                  {el.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
            <div className="form-fields">
                <p className="form-field-label"> Car Condition :</p>
                <Select
                  showSearch
                  value={form.new_condition}
                  onChange={(value) => handleDropdownChange("new_condition", value)}
                  style={{ width: "100%" }}
                  placeholder="-- Select --"
                  options={newCarConditions}
                />
            </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Built In Country : </p>
            <Input
              value={form.builtIn}
              onChange={(text) => handleFieldChange("builtIn", text)}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              No of Bidding 
            </p>
            <Select
              showSearch
              value={form.noOfBidding}
              onChange={(value) => handleDropdownChange("noOfBidding", value)}
              style={{ width: "100%" }}
              placeholder={"Select no. of bidding"}
            >
              {[
                { value: "1 Bids" },
                { value: "2 Bids" },
                { value: "3 Bids" },
                { value: "4 Bids" },
                { value: "5 Bids" },
                { value: "6 Bids" },
                { value: "7 Bids" },
                { value: "8 Bids" },
                { value: "Guarantee COE" },
              ].map((el, i) => (
                <Select.Option key={i} value={el._value}>
                  {el.value}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Launch Year :{" "}
            </p>
            <Select
              showSearch
              value={form.launchYear}
              onChange={(value) => handleDropdownChange("launchYear", value)}
              style={{ width: "100%" }}
              placeholder={"Select launch year"}
            >
              {launchYears.map((el, i) => (
                <Select.Option key={i} value={el}>
                  {el}
                </Select.Option>
              ))
              }
            </Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Category :{" "}
            </p>
            <Select
              showSearch
              value={form.newCategory}
              onChange={(value) => handleDropdownChange("newCategory", value)}
              style={{ width: "100%" }}
              placeholder={"Select category"}
            >
              {category.map((el, i) => (
                <Select.Option key={i} value={el}>
                  {el}
                </Select.Option>
              ))
              }
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
          <div className="label-with-tooltip">
              <p className="form-field-label"> Optional Label :</p>
              <FieldSuffix
                TooltipData={() => (
                  <p style={{ fontSize: "9px" }}>
                    Label shall be displayed on the picture of car.
                  </p>
                )}
              />
            </div>
            <Select
              value={form.option_label_1}
              onChange={(value) => handleDropdownChange("option_label_1", value)}
              style={{ width: "100%" }}
              placeholder="-- Select --"
              options={[
                { value: '$0 Downpayment', label: '$0 Downpayment' },
                { value: 'Consignment', label: 'Consignment' },
                { value: 'Test Drive Unit', label: 'Test Drive Unit' },
                { value: 'Showroom Unit', label: 'Showroom Unit' },
              ]}
              allowClear
            />
          </div>
        </Col>

        {/* <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Youtube Video : </p>
            <Input
              value={form.youtubeVideo}
              onChange={(text) => handleFieldChange("youtubeVideo", text)}
            />
          </div>
        </Col>

        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Tiktok Video : </p>
            <Input
              value={form.tiktokVideo}
              onChange={(text) => handleFieldChange("tiktokVideo", text)}
            />
          </div>
        </Col> */}
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Video Link : </p>
            <Input
              value={form.youtubeVideo}
              onChange={(text) => handleFieldChange("youtubeVideo", text)}
              placeholder="Youtube or Tiktok Video Link"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div className="form-fields" style={{ marginTop: 20 }}>
            <Checkbox
              valuePropName="checked"
              checked={form.isVerified}
              onChange={(e) => onCheckboxChange("isVerified", e)}
            >
              Is Verified
            </Checkbox>
          </div>
        </Col>
        
        <Col span={8}>
          <div className="form-fields" style={{ marginTop: 20 }}>
            <Checkbox
              valuePropName="checked"
              checked={form.isSendEmail}
              onChange={(e) => onCheckboxChange("isSendEmail", e)}
            >
              Is Send Email
            </Checkbox>
          </div>
        </Col>

      </Row>

      {isEdit && (
        <>
        <Divider />

        <div className="header-container">
          <p className="header">Sale Advisers</p>
        </div>

        <Row>
          {saleAdvisers?.map((item, i) => (
            <Col span={8}>
              <div className="form-fields" style={{ margin: 0 }}>
                <Checkbox
                  name={item}
                  checked={form?.productAgents?.find(agent => agent.fullName === item.fullName)}
                  onChange={(e) => handleUpdateAgent(e)}
                  disabled
                >
                  {item.fullName} - {item?.sorting}
                </Checkbox>
              </div>
            </Col>
          ))}
        </Row>
        </>
      )}

      <Divider />

      <Row>
        <Col span={6}>
          <PrimaryButton
            onClick={downloadFiles}
            color={colorScheme.green}
            label="Download Brochures"
            Icon={<FaDownload style={{ marginRight: "10px" }} />}
          />
        </Col>
      </Row>

      <Row>
        <div className="editor-container">
          <p className="form-field-label">
            Specs <span></span> :{" "}
          </p>
          <Editor
            editorState={specsEditorState}
            onEditorStateChange={handleSpecsEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              inline: { inDropdown: false },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: {
                component: ImageUploadComponent,
                previewImage: true,
                uploadEnabled: true,
                alt: { present: true, mandatory: true },
              },
            }}
          />
        </div>

        <div className="editor-container">
          <p className="form-field-label">
            Features <span></span> :{" "}
          </p>
          <Editor
            editorState={featEditorState}
            onEditorStateChange={handleFeatEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              inline: { inDropdown: false },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: {
                component: ImageUploadComponent,
                previewImage: true,
                uploadEnabled: true,
                alt: { present: true, mandatory: true },
              },
            }}
          />
        </div>

        <div className="editor-container">
          <p className="form-field-label">
            Description <span></span> :{" "}
          </p>
          <Editor
            editorState={descEditorState}
            onEditorStateChange={handleDescEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              inline: { inDropdown: false },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: {
                component: ImageUploadComponent,
                previewImage: true,
                uploadEnabled: true,
                alt: { present: true, mandatory: true },
              },
            }}
          />
        </div>
      </Row>

      <Divider />
      <div>
        <div
        style={{marginBottom: 10}}
        >
            <div className='header-div'>
                <p className='sub-header-text'>The image should not exceed 2MB(megabyte) and has to be (.jpg , .png) with a minimum size and same ratio of : 300 width x 600 length.</p>
            </div>
        </div>
        <Row>
          <Col span={8}>
            <div className="form-fields">
              {!form.longAdImage1 ?
              <div>
                <div className='upload-container col-div' onClick={handleOpenGalleryLong1}>
                    <FaUpload/>
                    <p>UPLOAD LONG PHOTO</p>
                </div>
                
                <input 
                    ref={fileUploaderLong1} 
                    id="getFileLong1" 
                    type="file" 
                    hidden
                    onChange={handleImageChange1}
                />
              </div>
              :
              <div style={{width:"100%", height:"auto",overflow:'hidden',position:"relative"}}>
                  <div 
                  style={{position:'absolute',right:0, top:0, backgroundColor:"#FFFFFF",height:40, width:40, margin:"0 auto"}} 
                  onClick={()=>handleDeleteSelectedFile1()}>
                      <IoMdCloseCircle style={{width:30, height:30, cursor:'pointer'}}/>
                  </div>
                  <img src={form.longAdImage1}
                  style={{width:"100%"}}
                  />
              </div>
              }
            </div>
          </Col>
          {/* <Col span={8}>
            <div className="form-fields">
              {!form.longAdImage2 ?
              <div>
                <div className='upload-container col-div' onClick={handleOpenGalleryLong2}>
                    <FaUpload/>
                    <p>UPLOAD LONG PHOTO</p>
                </div>
                
                <input 
                    ref={fileUploaderLong2} 
                    id="getFileLong2" 
                    type="file" 
                    hidden
                    onChange={handleImageChange2}
                />
              </div>
              :
              <div style={{width:"100%", height:"auto",overflow:'hidden',position:"relative"}}>
                  <div 
                  style={{position:'absolute',right:0, top:0, backgroundColor:"#FFFFFF",height:40, width:40, margin:"0 auto"}} 
                  onClick={()=>handleDeleteSelectedFile2()}>
                      <IoMdCloseCircle style={{width:30, height:30, cursor:'pointer'}}/>
                  </div>
                  <img src={form.longAdImage2}
                  style={{width:"100%"}}
                  />
              </div>
              }
            </div>
          </Col> */}
        </Row>
      </div>
      <Divider />
    </div>
  );
};

export default NewCarDetails;
